.connection_tab_container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
}

.input_container {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.connection_input {
  /* width: 150px;
  min-width: 150px !important; */
  width: 200px;
  margin-left: 20px;
}


.connection_text {
  width: 90px;
}

.checkbox_container {
  display: flex;
  justify-content: space-between;
}

.buttons_container {
  display: flex;
}


.checkbox {
  margin-left: 20px;
  margin-right: 10px;
}

.Error {
  color: red;
}

.checkBox_container {
  width : 310px;
}

.listTableContainer {
  margin-left: 5px;
  margin-right: 5px;
}

.listContainer {
  width: 100%;
  height: 80%;
}

