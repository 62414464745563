.TableSearchBarContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  margin-left: 10px;
  max-width: 90% !important;
  min-width: 170px;
}
.searchInput {
  max-width: 90% !important;
  margin-right: 5px;
}

.searchBarDatePickerContainer {
  margin-right: 5px;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(6, 7, 7, 0.15);
  align-items: center;
  border-radius: 0.28571429rem;
  background-color: #f0f0f0;
  width: 170px;
}

@media only screen and (max-width: 600px) {
  .searchBarDatePickerContainer {
    margin-left: 0px !important;
    margin-bottom: 5px;
    min-width: 205px;
  }
  .searchInput {
    width: 170px !important;
  }
}

.searchBarDateLabel {
  font-weight: 600;
  text-align: left;
  min-width: 63px;
}
