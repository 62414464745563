.landing_page_container {
  position: relative;
}

.header_container {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: white;
  padding: 20px 0 10px 0;
}

.start_btn_container {
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding-bottom: 20px;
}

.tabs_container {
  display: flex;
  flex: 1;
  border-bottom: 1px solid grey;
}

.tab_pane_container {
  padding: 10px 25px;
  margin-bottom: -1px;
  cursor: pointer;
}

.tabs_container .active {
  border: 1px solid grey;
  border-bottom: 1px solid white;
  border-radius: 7px 7px 0 0;
  z-index: 10;
}

.setting_tab_container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.input_container {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* width: 250px; */
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.settings_input {
  /* width: 60px; */
  margin-left: 20px;
}
.settings_text {
  width: 80px;
}

.listTableContainer {
  margin-left: 5px;
  margin-right: 5px;
}

.listContainer {
  width: 100%;
  height: 80%;
}

.iconLabelContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 30px !important;
}

.iconContainer {
  width: 70px;
  display: flex;
  justify-content: space-between;
}
