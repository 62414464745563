.building_selection_container {
  position: relative;
  height: 100vh;
  width: 100%;
  margin: auto;
  overflow: auto;
}

.background_image {
  width: 1000px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
}
