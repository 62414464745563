.setting_tab_container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 15px;
}

.input_container {
  /* display: flex; */
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.input_container_last {
  /* display: flex; */
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 50px;
}

.multiple_input_container {
  /* display: flex; */
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border: 1px solid #04798f;
}

.multiple_input_container_last {
  /* display: flex; */
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 50px;
  border: 1px solid #04798f;
}

.sub_input_container {
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.destroy_input_container {
  margin-left: 4px;
  display: block;
}

.box_container {
  align-items: center;
  margin-left: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sub_container {
  align-items: center;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.main_select {
  font-weight: 700;
}

.hero_container {
  min-width: auto !important;
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.settings_input {
  width: 60px;
  margin-left: 20px;
}
.settings_text {
  width: 200px;
}

.prayTypeInput {
  min-width: auto !important;
  margin-left: 10px;
  margin-right: 10px;
}

.saveButton {
  position: fixed;
  bottom: 20px;
  left: 40%;
}

.kaynakCheckBoxes {
  max-width: 350px;
  display: flex;
  justify-content: space-between;
}
