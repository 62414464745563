.topbar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #06ceb3; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #06ceb3, #04798f);
  background: linear-gradient(to bottom, #06ceb3, #04798f);
  padding: 7px 20px;
}

.logo {
  width: 55px;
  height: auto;
}

.menu_container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: #06ceb3; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #06ceb3, #04798f);
  background: linear-gradient(to bottom, #06ceb3, #04798f);
  z-index: 2000;
  padding: 20px;
}

.icon_container {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.list_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list_item {
  font-size: 24px;
  font-weight: 600;
  padding: 20px;
}

.list_item:hover a {
  color: #db2828;
}

.list_item a {
  color: white;
}

.select {
  min-width: 90px !important;
  background: none !important;
  border: none !important;
  font-weight: 500;
  box-shadow: none !important;
  color: white !important;
  margin-bottom: -20px;
  margin-top: -20px;
}
