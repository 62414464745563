.landing_page_container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100vh;
}

.header_container {
  background-color: white;
  padding-bottom: 10px;
}

.status_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.head_container {
  align-items: center;
  margin-bottom: 10px;
  padding-right: 10px;
  margin-top: 10px;
}

.warning_container {
  align-items: center;
  text-align: center;
  color: white;
  background-color: red;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 10px;
  font-size: 20px;
  font-weight: bolder;
  height: 25px;
  cursor: grab;
  text-decoration-line: underline;
}

.day_indicator_container {
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 10px;
  height: 25px;
}

.tabs_container {
  display: flex;
  justify-content: space-between;
  flex: 1;
  border: 1px solid #04798f;
  border-radius: 3px;
}

.tab_pane_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-weight: 500;
  padding: 10px 10px;
  margin-bottom: -1px;
  cursor: pointer;
  border-right: 1px solid #04798f;
}

.tabs_container .active {
  background-color: #04798f;
  color: white;
}

.Wait {
  color: magenta;
  font-size: large;
  margin-left: 10px;
}

.WaitClick {
  cursor: grab;
  color: magenta;
  text-decoration-line: underline;
  font-size: large;
  margin-left: 10px;
}

.ErrorClick {
  color: red;
  cursor: grab;
  text-decoration-line: underline;
  font-size: large;
  margin-left: 10px;
}

.Error {
  color: red;
  font-size: large;
  margin-left: 10px;
}
.Ok {
  color: green;
  font-size: large;
  margin-left: 10px;
}

.kayanyazi {
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
  max-width: 500px;
}

.kayanyazireverse {
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
  max-width: 500px;
}

.kayanyazi span {
  display: inline-block;
  padding-left: 100%;
  text-indent: 0;
  animation: marquee 50s linear infinite;
  font-weight: bold;
}

.kayanyazireverse span {
  display: inline-block;
  padding-left: 100%;
  text-indent: 0;
  animation: marqueeReverse 50s linear infinite;
  font-weight: bold;
}

.kayanyazi span:hover {
  animation-play-state: paused;
}
.kayanyazireverse span:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
@keyframes marqueeReverse {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
