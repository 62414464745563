.signin_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #06ceb3;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #06ceb3, #04798f);
  background: linear-gradient(to bottom, #06ceb3, #04798f); 
}

.logo {
  width: auto;
  height: 120px;
  margin-top: -40px;
}

.input_container {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-top: 10px;
}

.eye {
  margin-left: -30px;
  margin-top: auto;
  margin-bottom: auto;
}

.input_label {
  color: white;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}

.select_container {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.select {
  min-width: 110px !important;
}

.error_message {
  color: #762942;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

.route_text_container {
  color: #342aa0;
  font-size: 15px;
  margin-top: 20px;
  font-weight: 600;
}