.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.content_container {
  height: 100%;
  width: 100%;
  overflow: auto;
  max-width: 1400px;
}

.searchBarDateLabel {
  font-weight: 600;
  text-align: left;
  min-width: 63px;
}

.childContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
}
