.connection_tab_container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 0 15px;
}

.input_container {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.connection_input {
  width: 200px;
  margin-left: 20px;
}

.connection_text {
  width: 90px;
}

.checkbox_container {
  display: flex;
  justify-content: space-between;
}

.checkbox {
  margin-left: 20px;
  margin-right: 10px;
}

.Error {
  color: red;
}

.Info {
  margin-left: 7px;
  font-size: xx-large;
}
